import React, { useEffect, useState } from "react";
import { material } from "../../../library/material";
import Navbar from "../../navbar/Navbar";
import { Table, useTheme } from "@mui/material";
import { StyledTableCell } from "../../../shared/TableHeaderStyle";
import ClinicConfigDialog from "./ClinicConfigDialog";
import {
  getClinicHoliday,
  getEmailConfig,
  getPhoneConfig,
  getPrePopulateEmailSubject,
  getSmsRemainderData,
  saveSmsRemainderData,
} from "../../../services/ClinicConfigService";
import { connect } from "react-redux";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Snackbar from "../../toastrmessage/Snackbar";
import { useForm } from "react-hook-form";
import ClinicHoliday from "./ClinicHoliday";
import ClinicRoaster from "./ClinicRoaster";
import Protocol from "./protocol/Protocol";
import CurrentOffers from "./corrent-offers/CurrentOffers";

const label = {
  inputProps: {
    "aria-label": "Color switch demo",
    "aria-label2": "Checkbox demo",
  },
};

function ClinicConfig(props) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: "onTouched" });
  const { userData, clinicData } = props;
  const clinicDetails = clinicData.clinicReducer.data;
  const userDetails = userData.authReducer.data;
  const [expanded, setExpanded] = React.useState(false);
  const theme = useTheme();
  const [openClinicConfigDialog, setOpenClinicConfigDialog] = useState({
    action: false,
    callFrom: null,
    tableData: null,
  });
  const [prePopulateEmailSubjectData, setPrePopulateEmailSubjectData] = useState([]);
  const [emailConfigData, setEmailConfigData] = useState([]);
  const [smsConfigData, setSmsConfigData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState({
    action: false,
    type: "",
    message: "",
  });
  const [smsRemainderSettings, setSmsRemainderSettings] = useState(false);
  const [smsRemainderSettingsId, setSmsRemainderSettingsId] = useState(null);

  useEffect(() => {
    getPrePopulateEmail();
    getEmailConfigData();
    getSmsConfigData();
    getSmsRemainder();
  }, []);

  const getPrePopulateEmail = () => {
    setIsLoading(true);
    getPrePopulateEmailSubject(clinicDetails.clinicId)
      .then((resp) => {
        setPrePopulateEmailSubjectData(resp.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setOpenSnackBar({
          action: true,
          type: "error",
          message: error.response.data,
        });
      });
  };

  const getEmailConfigData = () => {
    setIsLoading(true);
    getEmailConfig(clinicDetails.clinicId)
      .then((resp) => {
        setEmailConfigData(resp.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setOpenSnackBar({
          action: true,
          type: "error",
          message: error.response.data,
        });
      });
  };

  const getSmsConfigData = () => {
    setIsLoading(true);
    getPhoneConfig(clinicDetails.clinicId)
      .then((resp) => {
        setSmsConfigData(resp.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setOpenSnackBar({
          action: true,
          type: "error",
          message: error.response.data,
        });
      });
  };

  const getSmsRemainder = () => {
    getSmsRemainderData(clinicDetails.clinicId)
      .then((resp) => {
        if (resp.data.length) {
          setSmsRemainderSettings(resp.data[0].send);
          setSmsRemainderSettingsId(resp.data[0].id);
        } else {
          setSmsRemainderSettings(false);
          setSmsRemainderSettingsId(null);
        }
      })
      .catch((error) => {
        setOpenSnackBar({
          action: true,
          type: "error",
          message: error.response.data,
        });
      });
  };

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const addEmailConfig = () => {
    setOpenClinicConfigDialog({
      action: true,
      callFrom: "email-config",
      tableData: null,
    });
  };

  const addPrePopulateEmail = () => {
    setOpenClinicConfigDialog({
      action: true,
      callFrom: "pre-populate-email",
      tableData: null,
    });
  };

  const addSmsConfig = () => {
    setOpenClinicConfigDialog({
      action: true,
      callFrom: "sms-config",
      tableData: null,
    });
  };

  const editEmailConfig = (rowData) => {
    setOpenClinicConfigDialog({
      action: true,
      callFrom: "email-config",
      tableData: rowData,
    });
  };

  const editPrePopulateEmail = (rowData) => {
    setOpenClinicConfigDialog({
      action: true,
      callFrom: "pre-populate-email",
      tableData: rowData,
    });
  };

  const editSmsConfig = (rowData) => {
    setOpenClinicConfigDialog({
      action: true,
      callFrom: "sms-config",
      tableData: rowData,
    });
  };

  const handleChangeRemainder = () => {
    const payload = {
      id: smsRemainderSettingsId,
      clinicId: clinicDetails.clinicId,
      orgId: userDetails.orgId,
      send: smsRemainderSettings ? false : true,
    };
    saveSmsRemainderData(payload)
      .then((resp) => {
        getSmsRemainder();
        setOpenSnackBar({
          action: true,
          type: "success",
          message: resp.data,
        });
      })
      .catch((error) => {
        setOpenSnackBar({
          action: true,
          type: "error",
          message: error.response.data,
        });
      });
  };

  return (
    <div>
      <Navbar />
      <material.Grid
        spacing={2}
        className="container-fluid"
        sx={{
          [theme.breakpoints.down("sm")]: {
            mt: 10,
          },
          [theme.breakpoints.up("sm")]: {
            mt: 10,
          },
          [theme.breakpoints.up("md")]: {
            mt: 12,
          },
          [theme.breakpoints.up("lg")]: {
            mt: 12,
          },
        }}
      >
        <material.Accordion
          expanded={expanded}
          onChange={handleExpansion}
          slots={{ transition: material.Fade }}
          sx={{
            "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
            "& .MuiAccordionDetails-root": {
              display: expanded ? "block" : "none",
            },
          }}
        >
          <material.AccordionSummary
            expandIcon={<material.ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <material.Typography sx={{ fontWeight: "bold" }}>
              Email Configuration
            </material.Typography>
          </material.AccordionSummary>
          <material.AccordionDetails>
            <material.Typography>
              <material.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <span className="float-end" style={{ marginTop: -40 }}>
                  <material.Button
                    variant="contained"
                    size="small"
                    sx={{ textTransform: "none" }}
                    onClick={addEmailConfig}
                  >
                    Add Email Config
                  </material.Button>
                </span>
              </material.Grid>
              <material.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <material.TableContainer>
                  <material.Table>
                    <material.TableHead>
                      <material.TableRow>
                        <StyledTableCell>Email</StyledTableCell>
                        <StyledTableCell>Email Service</StyledTableCell>
                        <StyledTableCell>Email Password</StyledTableCell>
                        <StyledTableCell>Purpose</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                      </material.TableRow>
                    </material.TableHead>
                    <material.TableBody>
                      {isLoading ? (
                        <material.TableRow>
                          <material.TableCell colSpan={10}>
                            <SkeletonTheme
                              baseColor="#bbdefb"
                              highlightColor="#c6ff00"
                              enableAnimation="true"
                              inline="true"
                              width="100% "
                              height="30px"
                            >
                              <Skeleton count={10} />
                            </SkeletonTheme>
                          </material.TableCell>
                        </material.TableRow>
                      ) : (
                        <>
                          {emailConfigData.length ? (
                            emailConfigData.map((item, k) => (
                              <material.TableRow
                                key={k}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  cursor: "pointer",
                                  ":hover": { backgroundColor: "lightgray" },
                                }}
                                onClick={() => editEmailConfig(item)}
                              >
                                <material.TableCell>
                                  {item.email_address}
                                </material.TableCell>
                                <material.TableCell>
                                  {item.email_service}
                                </material.TableCell>
                                <material.TableCell>
                                  {item.email_app_password}
                                </material.TableCell>
                                <material.TableCell>
                                  {item.purpose}
                                </material.TableCell>
                                <material.TableCell>
                                  {item.displayName}
                                </material.TableCell>
                              </material.TableRow>
                            ))
                          ) : (
                            <material.TableRow>
                              <material.TableCell colSpan={10}>
                                <h6 className="d-flex justify-content-center text-danger fw-bold">
                                  No data found
                                </h6>
                              </material.TableCell>
                            </material.TableRow>
                          )}
                        </>
                      )}
                    </material.TableBody>
                  </material.Table>
                </material.TableContainer>
              </material.Grid>
            </material.Typography>
          </material.AccordionDetails>
        </material.Accordion>
        <material.Accordion>
          <material.AccordionSummary
            expandIcon={<material.ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            sx={{ mt: 2 }}
          >
            <material.Typography sx={{ fontWeight: "bold" }}>
              Pre Populated Email for Schedule
            </material.Typography>
          </material.AccordionSummary>
          <material.AccordionDetails>
            <material.Typography>
              <material.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <span className="float-end" style={{ marginTop: -40 }}>
                  <material.Button
                    variant="contained"
                    size="small"
                    sx={{ textTransform: "none" }}
                    onClick={addPrePopulateEmail}
                  >
                    Add Pre-populate Email
                  </material.Button>
                </span>
              </material.Grid>
              <material.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <material.TableContainer>
                  <material.Table>
                    <material.TableHead>
                      <material.TableRow>
                        <StyledTableCell>Treatment</StyledTableCell>
                        <StyledTableCell>Instruction</StyledTableCell>
                        <StyledTableCell>Duration</StyledTableCell>
                      </material.TableRow>
                    </material.TableHead>
                    <material.TableBody>
                      {isLoading ? (
                        <material.TableRow>
                          <material.TableCell colSpan={10}>
                            <SkeletonTheme
                              baseColor="#bbdefb"
                              highlightColor="#c6ff00"
                              enableAnimation="true"
                              inline="true"
                              width="100% "
                              height="30px"
                            >
                              <Skeleton count={10} />
                            </SkeletonTheme>
                          </material.TableCell>
                        </material.TableRow>
                      ) : (
                        <>
                          {prePopulateEmailSubjectData.length ? (
                            prePopulateEmailSubjectData.map((item, k) => (
                              <material.TableRow
                                key={k}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  cursor: "pointer",
                                  ":hover": { backgroundColor: "lightgray" },
                                }}
                                onClick={() => editPrePopulateEmail(item)}
                              >
                                <material.TableCell>
                                  {item.treatmentName}
                                </material.TableCell>
                                <material.TableCell>
                                  {item.instructions}
                                </material.TableCell>
                                <material.TableCell>
                                  {item.duration}
                                </material.TableCell>
                              </material.TableRow>
                            ))
                          ) : (
                            <material.TableRow>
                              <material.TableCell colSpan={10}>
                                <h6 className="d-flex justify-content-center text-danger fw-bold">
                                  No data found
                                </h6>
                              </material.TableCell>
                            </material.TableRow>
                          )}
                        </>
                      )}
                    </material.TableBody>
                  </material.Table>
                </material.TableContainer>
              </material.Grid>
            </material.Typography>
          </material.AccordionDetails>
        </material.Accordion>
        <material.Accordion>
          <material.AccordionSummary
            expandIcon={<material.ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            sx={{ mt: 2 }}
          >
            <material.Typography sx={{ fontWeight: "bold" }}>
              SMS Config
            </material.Typography>
          </material.AccordionSummary>
          <material.AccordionDetails>
            <material.Typography>
              <material.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <material.Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <span>Remainder SMS Off</span>
                  <material.Switch
                    {...label}
                    color="secondary"
                    checked={smsRemainderSettings}
                    onChange={handleChangeRemainder}
                  />
                  <span>Remainder SMS On</span>
                </material.Stack>
                <span className="float-end flex-row" style={{ marginTop: -40 }}>
                  <material.Button
                    variant="contained"
                    size="small"
                    sx={{ textTransform: "none" }}
                    onClick={addSmsConfig}
                  >
                    Add SMS Config
                  </material.Button>
                </span>
              </material.Grid>
              <material.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <material.TableContainer>
                  <material.Table>
                    <material.TableHead>
                      <material.TableRow>
                        <StyledTableCell>Phone</StyledTableCell>
                        <StyledTableCell>Purpose</StyledTableCell>
                      </material.TableRow>
                    </material.TableHead>
                    {isLoading ? (
                      <material.TableRow>
                        <material.TableCell colSpan={10}>
                          <SkeletonTheme
                            baseColor="#bbdefb"
                            highlightColor="#c6ff00"
                            enableAnimation="true"
                            inline="true"
                            width="100% "
                            height="30px"
                          >
                            <Skeleton count={10} />
                          </SkeletonTheme>
                        </material.TableCell>
                      </material.TableRow>
                    ) : (
                      <>
                        {smsConfigData.length ? (
                          smsConfigData.map((item, k) => (
                            <material.TableRow
                              key={k}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                cursor: "pointer",
                                ":hover": { backgroundColor: "lightgray" },
                              }}
                              onClick={() => editSmsConfig(item)}
                            >
                              <material.TableCell>
                                {item.phoneNumber}
                              </material.TableCell>
                              <material.TableCell>
                                {item.purpose}
                              </material.TableCell>
                            </material.TableRow>
                          ))
                        ) : (
                          <material.TableRow>
                            <material.TableCell colSpan={10}>
                              <h6 className="d-flex justify-content-center text-danger fw-bold">
                                No data found
                              </h6>
                            </material.TableCell>
                          </material.TableRow>
                        )}
                      </>
                    )}
                  </material.Table>
                </material.TableContainer>
              </material.Grid>
            </material.Typography>
          </material.AccordionDetails>
        </material.Accordion>
        <material.Accordion>
          <material.AccordionSummary
            expandIcon={<material.ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            sx={{ mt: 2 }}
          >
            <material.Typography sx={{ fontWeight: "bold" }}>
              Clinic Hours
            </material.Typography>
          </material.AccordionSummary>
          <material.AccordionDetails>
            <material.Typography>
              <ClinicRoaster
                userDetails={userDetails}
                clinicDetails={clinicDetails}
              />
            </material.Typography>
          </material.AccordionDetails>
        </material.Accordion>
        <material.Accordion>
          <material.AccordionSummary
            expandIcon={<material.ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            sx={{ mt: 2 }}
          >
            <material.Typography sx={{ fontWeight: "bold" }}>
              Clinic Holiday
            </material.Typography>
          </material.AccordionSummary>
          <material.AccordionDetails>
            <material.Typography>
              <ClinicHoliday
                userDetails={userDetails}
                clinicDetails={clinicDetails}
              />
            </material.Typography>
          </material.AccordionDetails>
        </material.Accordion>
        <material.Accordion>
          <material.AccordionSummary
            expandIcon={<material.ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            sx={{ mt: 2 }}
          >
            <material.Typography sx={{ fontWeight: "bold" }}>
              Protocol
            </material.Typography>
          </material.AccordionSummary>
          <material.AccordionDetails>
            <material.Typography>
              <Protocol
                userDetails={userDetails}
                clinicDetails={clinicDetails}
              />
            </material.Typography>
          </material.AccordionDetails>
        </material.Accordion>
        <material.Accordion>
          <material.AccordionSummary
            expandIcon={<material.ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            sx={{ mt: 2 }}
          >
            <material.Typography sx={{ fontWeight: "bold" }}>
              Current Offers
            </material.Typography>
          </material.AccordionSummary>
          <material.AccordionDetails>
            <material.Typography>
              <CurrentOffers
                userDetails={userDetails}
                clinicDetails={clinicDetails}
              />
            </material.Typography>
          </material.AccordionDetails>
        </material.Accordion>
      </material.Grid>
      <ClinicConfigDialog
        openClinicConfigDialog={openClinicConfigDialog}
        setOpenClinicConfigDialog={setOpenClinicConfigDialog}
        getPrePopulateEmail={getPrePopulateEmail}
        getEmailConfigData={getEmailConfigData}
        getSmsConfigData={getSmsConfigData}
      />
      <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state,
    clinicData: state,
  };
};

export default connect(mapStateToProps)(ClinicConfig);
