import React from 'react';
import { useTheme } from '@mui/material';
import { material } from '../../../library/material';
import Navbar from '../../navbar/Navbar';

function CashFloat(props) {

    const theme = useTheme();

    return (
        <material.Grid spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 10
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 10
                },
            }}
        >
            <Navbar />
            <material.Grid container spacing={2}>

            </material.Grid>
        </material.Grid>
    );
}

export default CashFloat;